<template>
	<div class="login-warpper">
		<div class="login-box">
			<img class="title-img" :src="`${baseUrl}img/login/logon.png`">
			<h2 class="title-html">非法集资监测预警平台</h2>
			<div class="input-box">
				<div class="input-item">
					<el-input placeholder="输入用户名" name="用户名" v-model="login.usernames" prefix-icon="iconfont icon-yonghu1"></el-input>
				</div>
				<div class="input-item">
					<el-input placeholder="输入密码" name="密码" v-model="login.passWords" prefix-icon="iconfont icon-mima" type="password"></el-input>
				</div>
				<div class="input-item">
					<el-input placeholder="输入动态密码" name="动态密码" v-model="login.passWordsDt" prefix-icon="iconfont icon-mima2" type=""></el-input>
				</div>
				<div class="input-item">
					<el-button class="switch-btn" type="primary" @click="doLogin">{{loginBtn}}</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import md5 from 'js-md5' //加密
import axios from 'axios'
export default {
	name: 'Login',
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			loginBtn: "登 录",
			login: {
				usernames: '',
				passWords: '',
				passWordsDt: '',
			},
		}
	},
	created() {
	
	},
	mounted() {
		// 绑定enter事件
		this.enterKeyup();
	},
	destroyed() {
		// 销毁enter事件
		this.enterKeyupDestroyed();
	},
	computed: {},
	methods: {
		//登录
		doLogin() {
			let that = this;
			if (this.login.usernames == "") {
				this.$message({
					message: '用户名不能为空',
					type: 'warning'
				});
			} else if (this.login.passWords == "") {
				this.$message({
					message: '密码不能为空',
					type: 'warning'
				});
			} else if (this.login.passWordsDt == "") {
				this.$message({
					message: '动态密码不能为空',
					type: 'warning'
				});
			} else {
				this.loginBtn = '登 录 中';
				axios.post(this.$pulick.urlStr + '/wg-uaa/oauth/token?username=' + this.login.usernames + '&password=' + md5(this.login.passWords) + '&grant_type=totp2&scope=all&client_id=mate&client_secret=mate_secret', {}, {
					headers: {
						"code": this.login.passWordsDt,
						"platformId": 1
					}
				}).then(function(rustle) {
					sessionStorage.clear();
					let res = rustle.data;
					if (res.code == 200) {
						//token 存储
						//that.$store.commit('commitToken', res.data.accessToken);//token  vuex存储  
						let token_xjfj = { tokenInfo:res.data.accessToken };
						sessionStorage.setItem("token_xjfj", JSON.stringify(token_xjfj));
						//获取认证信息  存储
						that.$provider.get('/wg-uaa/auth/get/user', {}).then(res => {
							let user_xjfj = {
								userName:res.data.userName,
								provinceName:res.data.provinceName,
								provinceId:res.data.provinceId,
								cityName:res.data.cityName,
								cityId:res.data.cityId,
								districtName:res.data.districtName,
								districtId:res.data.districtId,
								level:res.data.level,
								defaultMenuList:res.data.permissions[0].children,
								defaultActive:res.data.permissions[0].children[0].id,
								skinInfo:'white'
							}
							sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
							that.$router.push({
								path: '/overallSituation',
							})
						})
					}
				}).catch(function(error) {
					let data = error.response;
					that.loginBtn = '登 录';
					that.$message.error(data.data.msg);
					that.login.usernames = '';
					that.login.passWords = '';
					that.login.passWordsDt = '';
				});
			}
		},
		//回车
		enterKey(event) {
			const code = event.keyCode ?
				event.keyCode :
				event.which ?
				event.which :
				event.charCode;
			if (code == 13) {
				this.doLogin();
			}
		},
		enterKeyupDestroyed() {
			document.removeEventListener("keyup", this.enterKey);
		},
		enterKeyup() {
			document.addEventListener("keyup", this.enterKey);
		},
	}
}
</script>

<style lang="stylus">
	@import "/xjfj/css/style-login.css"
	// @import "/css/style-login.css" 
</style>
